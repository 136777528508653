@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.show{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: 1;
    transition: 200ms;
}

.hide{
    opacity: 0;
    transition: 200m;
    display: none;
}

.root{
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.boxText{
    font-size: 17px;
    color: #505256;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
    text-align: center;
  }

.button{
    font-family: Outfit;
    cursor: pointer;
    padding: 10px;
    color: white;
    text-align: center;
    inline-size: 200px;
    background-color: #53a0ff;
    border-radius: 12px;
    box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 6px 6px -1px rgb(8 11 14 / 10%);
    transition: 200ms;
}

.button:hover{
    opacity: 0.5;
    transition: 200ms;
}

.buttonInvalid{
    font-family: Outfit;
    cursor: pointer;
    padding: 10px;
    color: white;
    text-align: center;
    inline-size: 100px;
    background-color: #4b4b4b;
    border-radius: 12px;
    box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 6px 6px -1px rgb(8 11 14 / 10%);
    transition: 200ms;
    margin-right: 15px;
}


.buttonS{
    font-family: Outfit;
    cursor: pointer;
    padding: 10px;
    color: white;
    text-align: center;
    inline-size: 100px;
    background-color: #53a0ff;
    border-radius: 12px;
    box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 6px 6px -1px rgb(8 11 14 / 10%);
    transition: 200ms;
    margin-right: 15px;
}

.buttonS:hover{
    opacity: 0.5;

    transition: 200ms;
}

.buttonS:active{
   background-color: aquamarine;
}


.subText{
    font-size: 8px;
    color: #505256;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    
    margin-top: 3vh;
}

.back{
    color: #53a0ff;
    font-style: italic;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
}

.back:hover{
    opacity: 0.4;
}

.pund{
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 3px;
    border-width: 0px;
    padding-right: 3px;
    
}

.input{
    font-size: 22px;
    color: #000000;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
    outline: none;
}

textarea:focus, input:focus{
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Outcome{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
}

.OutcomeIn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.OutcomeInR{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-left: solid;
    border-left-width: 1px;
    border-color: #00000028;
    margin-left: 20px;
    padding-left: 30px;
}

.bigtext{
    font-size: 30px;
    font-weight: 700;
    color: #000000;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

@media only screen and (max-width: 600px){

    .show{
        justify-content: center;
        align-items: center;
    }

}