@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');






.root{
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  perspective: 10px;
}

.bod{
  margin-top: 3vh;
  background-color: white;
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 0px;
}

.headLogo{
  height: 60px;
}

.headerTop{
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 89%;
  margin-bottom: 15px;
}

.headerTopRight{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.FirstPan{
  width: 89%; 
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  z-index: -1;
  border-radius: 12px;
  background-color: white;
}

.LogoMid {
  position: absolute;
  height: 280px;
  z-index: -1;
  animation: fadeIn 3s forwards;
  filter: drop-shadow(7px 7px 2px rgba(0, 0, 0, 0.3));
}

.SmLogo{
  height: 70px;
  margin: 0px;
  padding: 0px;
  opacity: 0.48;
  margin-right: 60px;
}

.FirstPanImage {    
    border: none;
    width: 200%;
    height: 200%;
    top: 0;
    left: 0;
    opacity: 0.8;
  
  /* transform: translateZ(-0.5px) scale(1.05); */
  animation: slowin forwards 5s;
  border-radius: 12px;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
}

@keyframes slowin {
  0% {opacity: 0;}
  15% {opacity: 0;}
  100% {opacity: 0.8;}
}

.menuButton{
  font-family: Outfit;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
  border-radius: 12px;
  color: black;
  cursor: pointer;
  font-weight: 500;
  transition: 300ms;
  text-decoration: none;
  font-size: 15px;
  animation: fadeIn 2s forwards;
  
}

.IconTrans{
  cursor: pointer;
  height: 25px;
  padding-right: 10px;
  padding-left: 10px;
  transition: 200ms;
}

.IconTrans:hover{
  opacity: 0.6;
  transition: 200ms;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.LogoText{
  font-family: Outfit;
  padding: 10px;
  padding-left: 0px;
  padding-right: 15px;
  margin: 5px;
  border-radius: 12px;
  color: black;
  font-weight: 500;
  text-decoration: none;
  font-size: 15px;
  animation: fadeIn 2s forwards;
}


.menuButton:hover{
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  margin: 5px;
  border-radius: 12px;
  color: #53a0ff;
  cursor: pointer;
  font-weight: 500;
  transition: 300ms;
}



.boxImage{

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px;
  margin: 10px;
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 12px;
  border: 2px solid #53a0ff;
  opacity: 0.95;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
}



.TextPannel{
  
  margin-top: 1vh;
  margin-bottom: vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 86%;
  background-color: white;
  color: black;
  
  border-radius: 12px;
  border: 1px solid #F7F8F9;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 40px;
  transition: 200ms;

}

.ContactPannel{
  margin-top: 15vh;
  height: 15vh;
  margin-bottom: vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 86%;
  background-color: white;
  color: black;
  
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 40px;
  transition: 200ms;

  box-shadow: none;
}


.ContactCont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.TextPannel:hover{
  width: 88%;
  transition: 200ms;
}

.textHeader{
  font-family: Outfit;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 25px;
   color: #53a0ff;
}


.textPara{
  font-size: 17px;
  color: #505256;
  line-height: 30px;
  margin: 3px;
  padding-right: 80px;
}

.Pannels{
  margin-top: 3vh;
  display: flex;
  width: 90%;
  color: black;
  justify-content: space-around;
}



.box{
  padding: 40px;
  margin: 10px;
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 12px;
  border: 1px solid #F7F8F9;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
  transition: 200ms;
}

.box:hover{
  transform: translateY(-10px);
  box-shadow: 0 20px 10px 0 rgb(8 11 14 / 6%), 0 6px 6px -1px rgb(8 11 14 / 6%);
  transition: 200ms;
}

.boxHeader{
  font-family: Outfit;
  font-size: 22px;
  font-weight: 500;
  
}

.boxText{
  font-size: 17px;
  color: #505256;
  line-height: 30px;
  margin: 0px;
  padding: 0px;
}

.icon{
  max-height: 30px;
  filter: brightness(0) invert(1);

}

.iconBox{
  border-radius: 5px;
  height: 35px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Section2{
  margin-top: 2vh;
  width: 89%;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #F7F8F9;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
  transition: 200ms;
}

.SectionInstruct{
  margin-top: 2vh;
  width: 89%;
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #F7F8F9;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
  transition: 200ms;
}

.SecInstructImg{
  height: 350px;
}

.InstuctLeft{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}

.StampSection{
  margin-top: 4vh;
  margin-bottom: 3vh;
  width: 89%;
  min-height: 55vh;
  height: 55vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #F7F8F9;
  box-shadow: 0 1px 1px 0 rgb(8 11 14 / 6%), 0 4px 4px -1px rgb(8 11 14 / 6%);
  transition: 200ms;
}



.Section2Image{
  margin-left: 53px;
  margin-right: 20px;
  position: relative;
  height: 450px;
}

.StampImg{
  position: relative;
  height: 450px;
  margin: 0px;
  height: 70vh;
}

.Secontion2Right{
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.StampCont{
  padding-left: 50px;
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.Pannel2{
  display: flex;
  width: 100%;
  flex-direction: row;
  color: black;
  justify-content: space-evenly;
}

.box2{
  padding: 5px;
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.icon2{
  max-height: 35px;
}

.textHeader2{
font-size: 20px;
font-weight: 500;
margin-top: 15px;
margin-left: 5px;
margin-bottom: 0px;
}

.text2{
  font-size: 14px;
  line-height: 24px;
  inline-size: 150px;
  color: #505256;
  margin-left: 5px;
}

.ImgGreet{
  padding-right: 60px;
  vertical-align: middle;
}


.logosCont{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}




.img{
  max-height: 200px;
  position: absolute;
  top: -120px;
  pointer-events: none;
  z-index: 1;
}



@media only screen and (max-width: 1200px){
  .Section2{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .textHeader{
    text-align: center;
    margin-top: 20px;
  }
  
  .Secontion2Right{
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-left: 0px;
  }
  
  .Pannel2{
    margin-bottom: 50px;
  }
  
  .SectionInstruct{
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .InstuctLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  
    
    .logosCont{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  
    .SmLogo{
      height: 50px;
      margin: 0px;
      padding: 10px;
      opacity: 0.48;
    }
    
  }


  @media only screen and (max-width: 900px){
    .StampImg{
      display: none;
    }
  }

@media only screen and (max-width: 600px){

  .menuButton{
    flex: 1;
    font-size: 10px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    
  }

  .headerTop{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-bottom: 22px;
  }

  .headerTopRight{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  
.headLogo{
  height: 35px;
}

.header{
  font-size: 40px;
}

.TextPannel{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: auto;
}

.textHeader{
  text-align: center;
  margin-top: 20px;
  
}

.textPara{
  inline-size: auto;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 30px;
}

.ImgGreet{
  padding-right: 0px;
  padding-top: 30px;
}

.Pannels{
  display: block;
  flex-wrap: wrap;
  width: 89%;
  
}
.Pannel2{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box2{
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: #a0a0a08d;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.textHeader2{
  margin-left: 0px;
}
.text2{
  margin-left: 0px;
  text-align: center;
}

.boxImage{
  height: 190px;
}

.Section2{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Secontion2Right{
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-left: 0px;
}

.StampCont{
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  
}

.Section2Image{
  padding-top: 40px;
  height: 60vw;
  margin: 0px;
}

.StampImg{
  display: none;
}

.StampSection{
  margin-top: 10px;
  min-height: 65vh;
  height: 65vh;
  width: 85%;
}

.ContactPannel{
  margin-top: 20px;
  height: auto;
  padding: 0px;
}

.ContactCont{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logosCont{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.SmLogo{
  height: 40px;
  margin: 5px;
  padding: 5spx;
  opacity: 0.48;
}

.box{
  justify-content: center;
  align-items: center;
}

.boxText{
  text-align: center;
}


.LogoMid {
  position: absolute;
  height: 150px;
  z-index: -1;
  animation: fadeIn 3s forwards;
  filter: drop-shadow(7px 7px 2px rgba(0, 0, 0, 0.3));
}

.LogoText{
  display: none;
}


.SecInstructImg{
  height: 63vw;
}

}
