@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');



.show{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: 1;
    transition: 200ms;
}

.SubText{
    font-family: Outfit;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 25px;
     color: #53a0ff;
}

.hide{
    opacity: 0;
    transition: 200m;
    display: none;
}


.boxText{
    font-size: 17px;
    color: #505256;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
    text-align: center;
  }



.button:hover{
    opacity: 0.5;
    transition: 200ms;
}

.buttonInvalid{
    font-family: Outfit;
    cursor: pointer;
    padding: 10px;
    color: white;
    text-align: center;
    inline-size: 100px;
    background-color: #4b4b4b;
    border-radius: 12px;
    box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 6px 6px -1px rgb(8 11 14 / 10%);
    transition: 200ms;
    margin-right: 15px;
}

.form{
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.img{
    max-height: 200px;
    position: absolute;
    top: -142px;
    right: 7px;
    pointer-events: none;
    z-index: 1;
}


.first{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 10px;
    margin: 10px;
}

.Inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
}



.input{
    width: 100%;
    padding: 15px;
    font-size: 22px;
    border-radius: 12px;
    color: black;
    border-width: 0px;
    background-color: #53a0ff25;
    transition: 200ms;
}

.input:focus{
    transition: 200ms;
    background-color: #53a0ff71;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5052565b;
    opacity: 1; /* Firefox */
  }
  


.button{
    font-family: Outfit;
    cursor: pointer;
    flex: 1;
    
    
    font-size: 22px;
    border-radius: 12px;
    color: white;
    border-width: 0px;
    outline: none;
    background-color: #53a0ff;
    box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 6px 6px -1px rgb(8 11 14 / 10%);
    transition: 200ms;
    border: 0px;
    
}


@media only screen and (max-width: 600px){

    .Inner{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .first{
        flex-wrap: wrap;
    }

    .img{
        
        top: -117px;
        right: 7px;
        
    }
    
}